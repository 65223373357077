<template>
  <div class="channel flex">
    <div class="demo">
      <div class="h2 mgb-24">
        <span class="tpointer"></span>
        <span class="ttxt">示例</span>
      </div>
      <div class="phone_pic">
        <!-- 内嵌iframe -->
        <div class="phone_box">
          <iframe
            v-if="channal_url"
            id='iframe'
            frameborder='0'
            scrolling="yes"
            :src="channal_url"
            style="width: 100%;border: 0px;margin:0;"
          ></iframe>
        </div>

      </div>
    </div>
    <div class="codes mgl-8">
      <div class="h2 mgb-24">
        <span class="tpointer"></span>
        <span class="ttxt">编辑区</span>
        <a-tooltip>
          <template slot="title">
            1、拖拽排序；<br />
            2、鼠标移入展示按钮，可选择编辑、删除等；<br />
            3、顶部的“+添加频道”：添加一级频道；其余的“+”：添加子频道；<br />
            4、删除频道：有下级频道，下级频道随之一起删除，若频道之中有图文信息，图文信息自动解除与频道的关联，并下架放置素材管理-草稿箱中；<br />
            5、单击频道：会在左侧的预览区域展示所点信息。<br />
          </template>
          <a-icon
            type="info-circle"
            class="font-16 font5 mgl-4"
          />
        </a-tooltip>
      </div>
      <a-row class="filter-wrap mgb-18">
        <a-button
          type="primary"
          class="table-btn-clk  "
          style="height: 32px;"
          @click="addRules('')"
        >
          <i
            class="meiye-icon meiye-routeicon-add-copy font-12 mgr-4"
            style=" font-size:12px; "
          ></i>
          添加频道
        </a-button>
      </a-row>
      <div class="table_head flex align-item-center mgb-6">
        <span class="wid_298">名称</span>
        <span class="one wid_298">类型</span>
        <span class="wid_298 ">封面图</span>
      </div>
      <div class="table_body">
        <!-- 树形列表 -->
        <a-tree
          class="draggable-tree"
          draggable
          show-icon
          :icon="customExpandIcon"
          :tree-data="gData"
          @dragenter="onDragEnter"
          @dragstart="onDragstart"
          @dragend="onDragend"
          @drop="onDrop"
          :expandedKeys="expandedKeys"
          @expand="onExpand"
          :autoExpandParent="autoExpandParent"
          @select="onchangeTree"
          v-model="channal_id"
          :replace-fields="{children:'child', key:'id',  title: 'name'}"
        >
          <i
            slot="switcherIcon"
            class='meiye-icon meiye-jiantou meiye-jiantou_tree1'
            style="font-size: 12px;"
          ></i>
          <template
            slot="title"
            slot-scope="{ title, type_name, isEdit, pid,id,child,pic,index}"
          >
            <span
              v-if="!isEdit"
              class="bgLine"
            >
              <span class="title1">
                <!-- id-- {{id}} -->
                <!-- || index--{{ index }} -->
                <a-tooltip placement="topLeft">
                  <template slot="title">
                    <span>{{ title }}</span>
                  </template>
                  <span class="title1_sp"> {{ title||'--' }} </span>
                </a-tooltip>
              </span>
              <span class="title2">

                {{ type_name||'--'}}</span>

              <span class="title4">
                <img
                  v-if="pic"
                  style="width: 32px;height:32px;border:1px solid #d2e6e4;background: #fff; border-radius: 4px;"
                  :src="pic"
                  alt="avatar"
                />
                <span v-else>--</span>
              </span>
              <span class="title3">
                <!-- 加号按钮 -->
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>添加子频道</span>
                  </template>
                  <a-button
                    @click.self="addRules(id)"
                    class="smallbtn msbtn"
                  ><i class="meiye-icon meiye-routeicon-add-copy "></i></a-button>
                </a-tooltip>
                <!-- 下拉框 ... 按钮 -->
                <a-dropdown
                  :trigger="['click']"
                  placement="bottomLeft"
                  :overlayStyle='{"width":"80px"}'
                  overlayClassName="mydropdown"
                  @visibleChange="e=>changeStatus(e,'statusIsshow')"
                >
                  <span
                    class="msbtn pdb-24"
                    @click="event=>clickEvent(event,id)"
                  >
                    <span class="icon">···</span>
                  </span>
                  <a-menu slot="overlay">
                    <a-menu-item
                      v-for="(v) in filters_status"
                      :key="v.text"
                      @click=";getbrandsItem('status',v,id,pid,child,pic);statusIsshow=false;"
                      style="text-align:center;margin:0px 5px;padding: 5px 0px;border-radius: 3px;"
                    >
                      {{ v.text }}
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
              </span>
            </span>
            <span
              v-else
              class="height-32"
            >
              <span class="title1">
                <a-input
                  class="ant-input_1 wid-180"
                  placeholder="频道名称，10字以内"
                  :maxLength="100"
                  v-model="valueName"
                ></a-input>
              </span>
              <span class="title2">
                <a-select
                  class="wid-180"
                  optionFilterProp="label"
                  showSearch
                  allowClear
                  v-model="typesValue"
                  @change="event=>getbrandsItem( 'types',event,id)"
                  placeholder="选择分类"
                >
                  <a-select-option
                    v-for="it in typesList"
                    :key="it.id"
                    :value="it.id"
                    :label="it.name"
                  >
                    {{ it.name }}
                  </a-select-option>
                </a-select>
              </span>
              <span class="title4">
                <a-upload
                  :showUploadList="false"
                  :customRequest="e=>uploadFiles(e,id)"
                  :beforeUpload="beforeUpload"
                  @change="handleChangeTy($event,id)"
                >
                  <div
                    class="uploadpopBox"
                    v-if="pic || query.pic"
                  >
                    <img
                      style="width: 32px;height:32px;border:2px solid #fff;background: #fff; box-shadow: 0px 2px 5px 0px #D4DAD5;border-radius: 2px;"
                      :src="query.pic"
                      alt="avatar"
                    />
                  </div>
                  <div
                    v-else
                    class="typeicon"
                  >
                    <a-icon
                      class="typeicon_icon"
                      :type="loading ? 'loading' : 'plus'"
                    />
                  </div>
                </a-upload>
              </span>
              <span class="title3 edit_title3">
                <a-button
                  size="small"
                  class="mgr-12 small_primary"
                  type="primary"
                  @click="handleBatch2('save',key)"
                >保存</a-button>
                <a-button
                  size="small"
                  class="mgr-12 smallbtn"
                  @click="handleBatch2('cancle',id,pid)"
                >取消</a-button>
              </span>
            </span>
          </template>
        </a-tree>
      </div>
    </div>
  </div>
</template>
<script>
import config from '../config';
import {
  GetGoodslist,
  GetChannel,
  DeleteChannel,
  AddChannel,
  GetChannelType,
  UpFlie,//上传
  EditChannel,//编辑频道
  DragType,//拖拽频道
} from '@/api/content'
import axios from 'axios'
import storage from 'store'
export default {
  data() {
    return {
      loading: false,
      clickFlag: false,
      toolFlag: false,
      defaultFileList: [],
      gData: [],
      channal_id: undefined,
      channal_obj: {
        type: undefined,
        title: undefined
      },
      channal_url: undefined,
      typesList: [],
      typesValue: undefined,
      valueName: undefined,
      pid: undefined,
      type_id: undefined,
      channalid: undefined,
      filters_status: [
        { text: '编辑', value: '1', key: 1 },
        { text: '删除', value: '2', key: 2 },
      ],
      baseUrl: process.env.VUE_APP_API_BASE_URL_MALL,
      statusIsshow: false,
      expandedKeys: [],
      autoExpandParent: false,
      query: {
        page: 1,
        per_page: 100,
        pic: undefined
      },
      start_id: undefined,
      start_index: undefined,
      end_index: undefined,
      pid_new: undefined,
    }
  },
  methods: {
    onDragEnter(info) {
      console.log(info, '进入的出去的要找到对应关系');
      // 找到id对应gData的index 记录结束位置 
      console.log(this.gData, '。。。原本数据');
    },
    onDragend({ event, node }) {
      console.log(event.eventKey, node.eventKey, '拖拽结束结束');
      this.end_index = this.getIndex(this.gData, node.eventKey)
      console.log(this.start_id, 'this.start_id');
      // this.start_id
      // this.start_index
      // this.end_index
      console.log(this.gData, '。。。原本数据');
      // this.dragFn()
    },
    onDragstart({ event, node, dragNode, dragNodesKeys }) {
      if (!node.dataRef.id || node.dataRef.isEdit) {
        event.preventDefault();
        return false
      }
      console.log(event.eventKey, node.eventKey, dragNode, dragNodesKeys, '拖拽开始');
      // 找到id对应gData的index 记录初始位置
      this.start_id = node.eventKey
      this.start_index = this.getIndex(this.gData, node.eventKey)
    },

    onDrop(info) {
      console.log(info);
      const dropKey = info.node.eventKey;
      const dragKey = info.dragNode.eventKey;
      const dropPos = info.node.pos.split('-');
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
      console.log(
        '目标节点',
        info.node.pos,
        info.node.dataRef.title,
        info.node.dataRef.index,
        info.dropPosition
      );
      console.log(
        '当前节点',
        info.dragNode.pos,
        info.dragNode.dataRef.title,
        info.dragNode.dataRef.index,
        info.dropPosition
      );
      this.start_index = info.dragNode.dataRef.index
      this.end_index = info.dropPosition == -1 ? info.node.dataRef.index : (info.node.dataRef.index + 1)

      this.start_id = info.dragNode.dataRef.id
      if (info.dragNode.dataRef.pid == info.node.dataRef.pid && dropPosition) {
        this.pid_new = info.dragNode.dataRef.pid_new
      } else if (info.dragNode.dataRef.pid == info.node.dataRef.pid && dropPosition == 0) {
        this.pid_new = info.node.dataRef.id
      } else if (info.dragNode.dataRef.pid == info.node.dataRef.pid) {
        this.pid_new = info.node.dataRef.pid
      } else if (info.node.dataRef.id == info.dragNode.dataRef.pid && dropPosition != -1) {
        this.pid_new = info.node.dataRef.id
      } else if (info.node.dataRef.pid == 0 && dropPosition == -1) {
        this.pid_new = info.node.dataRef.pid
      } else {
        this.pid_new = 0
      }
      const loop = (data, key, callback) => {
        data.forEach((item, index, arr) => {
          if (item.key === key) {
            return callback(item, index, arr);
          }
          if (item.child) {
            return loop(item.child, key, callback);
          }
        });
      };
      const data = [...this.gData];
      // Find dragObject
      let dragObj;
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);
        dragObj = item;
      });
      if (!info.dropToGap) {
        // Drop on the content
        loop(data, dropKey, item => {
          item.child = item.child || [];
          // where to insert 示例添加到尾部，可以是随意位置
          item.child.push(dragObj);
        });
      } else if (
        (info.node.child || []).length > 0 && // Has children
        info.node.expanded && // Is expanded
        dropPosition === 1 // On the bottom gap
      ) {
        loop(data, dropKey, item => {
          item.child = item.child || [];
          // where to insert 示例添加到尾部，可以是随意位置
          item.child.unshift(dragObj);
        });
      } else {
        let ar;
        let i;
        loop(data, dropKey, (item, index, arr) => {
          ar = arr;
          i = index;
        });
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj);
        } else {
          ar.splice(i + 1, 0, dragObj);
        }
      }
      this.gData = data;
      let obj = Object.assign({}, data)
      obj = JSON.stringify(obj)
      console.log("前端排序好的数据：", this.gData);
      // debugger//
      console.log("前端准备传的数据：", obj);
      this.dragFn(obj)
    },
    dragFn(str) {
      // let data = {
      //   id: this.start_id,
      //   pid_new: this.pid_new,
      //   sort_old: this.start_index,
      //   sort_new: this.end_index
      // } 
      DragType({ json_detail: str }).then(res => {
        if (res.error_code == 0) {
          // this.$message.success(res.data.message)
          this.getList()
        }
      })
    },
    // 添加频道
    addRules(id) {
      if (this.clickFlag) {
        return this.$message.error('请先保存编辑项！')
      }
      this.pid = undefined
      this.typesValue = undefined
      this.valueName = undefined
      this.type_id = undefined
      this.channalid = undefined
      this.clickFlag = true
      if (id) {//添加子频道
        this.pid = id
        this.addChildChannel(this.gData, id)
        console.log(this.gData);
        this.expandedKeys.push(id)
        this.$forceUpdate()
      } else {
        // table_body ul 滑动到顶部
        document.querySelector('.table_body > ul').scrollIntoView()
        this.pid = 0
        this.gData.unshift({
          pid: 0,
          name: '',
          type_name: '',
          type_id: '',
          isEdit: true
        })
        // 
      }
      // this.clickFlag = false
    },
    // 递归算出 index
    getIndex(data, id) {
      let index = ''
      data.forEach(el => {
        if (el.id == id) {
          index = el.index
        }
        if (el.child && el.child.length) this.treeRecursion(el.child);
      })
      return index
    },
    // 保存save  //取消cancle
    handleBatch2(key, id, pid) {
      if (key == 'save') {
        console.log(this.pid);
        this.addChannel()
      }
      if (key == 'cancle') {
        this.pid = undefined
        this.valueName = undefined
        this.typesValue = undefined
        this.type_id = undefined
        this.channalid = undefined
        this.query.pic = undefined
        if (id) {
          this.changeTree(this.gData, id, 'isEdit', false)
        } else {
          // 区分是第一个取消 还是有子项的取消
          if (!pid) {
            this.gData.shift()
          } else {
            this.deleteChildChannel(this.gData, pid)
            this.$forceUpdate()
          }
        }

      }
      this.clickFlag = false
    },
    // 递归添加子组件
    addChildChannel(data, id) {
      let obj = {
        pid: id,
        name: '',
        type_name: '',
        type_id: '',
        isEdit: true
      }
      data.forEach(el => {
        if (el.id == id) {
          if (el['child']) {
            el['child'].unshift(obj)
          } else {
            el['child'] = [obj]
          }
        }
        if (el.child && el.child.length) this.addChildChannel(el.child, id);
      })
    },
    // 递归删除子组件
    deleteChildChannel(data, pid) {
      data.forEach(el => {
        if (el.id == pid) {
          if (el['child']) {
            el['child'].shift()
            if (!el['child'].length) {
              delete el['child']
            }
          }
        }
        if (el.child && el.child.length) this.deleteChildChannel(el.child, pid);
      })
    },
    //递归 转化树形结构 
    treeRecursion(data) {
      data.forEach(el => {
        if (typeof (el.id) == 'number') {
          el.id = el.id.toString()
        }
        el['key'] = el.id
        el['title'] = el.name
        el['isEdit'] = false
        if (el.child && el.child.length) this.treeRecursion(el.child);
      })
    },
    // 自定义展开关闭图标
    customExpandIcon(props) {
      if (props.child && props.child.length > 0) {
      } else {
        if (!props.id) {
          return <span style="margin-right:19px"></span>;
        }
        //这里是为了让无图标子级的父元素也给了个margin-right，让它跟有子级的父元素在同一竖线上
        return <span style="margin-right:19px"> <span class='pointer_tree '></span></span>;
      }
    },
    changeStatus(e, i) {
      console.log(e);
      this[i] = e
    },
    // 点击下拉框
    getbrandsItem(e, event, id, pid, child, pic) {
      if (e == 'types') {
        console.log(event, 'types.......');
        console.log(e, event, id);
      }
      if (e == 'status') {
        if (this.clickFlag) {
          return this.$message.error('请先保存编辑项！')
        }
        // this.clickFlag = true
        if (event.key == 1) {//编辑1 删除2
          this.changeTree(this.gData, id, 'isEdit', true)
          console.log(this.gData, 'this.gData.......');
          this.clickFlag = true
          this.channalid = id
          this.pid = pid
          this.query.pic = pic
          this.$forceUpdate()
        } else {
          console.log(child, '/this.child');
          let self = this
          if (child && child.length) {
            self.$confirm({
              icon: 'exclamation-circle',
              closable: true,
              title: '提示',
              content: (<div> 删除当前频道，其下全部子频道及与之关联的图文的关联关系也将被删除，确认删除吗 ？</div>),
              okText: '确定',
              closable: true,
              okType: 'primary',
              cancelText: '返回',
              onOk() {
                self.changeTree(self.gData, id, 'delete')
                self.clickFlag = true
                self.$forceUpdate()
              },
              onCancel() {
              },
            });

          } else {
            self.$confirm({
              icon: 'exclamation-circle',
              closable: true,
              title: '提示',
              content: (<div> 删除之后将不在列表展示，是否确认删除 </div>),
              okText: '确定',
              closable: true,
              okType: 'primary',
              cancelText: '返回',
              onOk() {
                self.changeTree(self.gData, id, 'delete')
                self.clickFlag = true
                self.$forceUpdate()
              },
              onCancel() {
              },
            });

          }


        }
      }
    },
    // 递归改变树形结构
    changeTree(data, id, key, flag) {
      if (key == 'isEdit') {
        data.forEach(el => {
          if (el.id == id) {
            el[key] = flag
            if (flag) this.typesValue = el.type_id
            if (flag) this.valueName = el.name
          }
          if (el.child && el.child.length) this.changeTree(el.child, id, key, flag);
        })
      }
      if (key == 'delete') {
        data.forEach(el => {
          if (el.id == id) {
            this.delete(id)
          }
          if (el.child && el.child.length) this.changeTree(el.child, id, key, flag);
        })
      }
    },
    // 删除频道
    delete(id) {
      let data = {
        id: [id]
      }
      DeleteChannel(data).then(res => {
        if (res.error_code == 0) {
          this.$message.success(res.data.message)
          this.statusIsshow = false
          this.channal_url = undefined
          this.getList()
        }
      })
    },
    // 获取树形列表
    getList() {
      GetChannel().then(res => {
        if (res.error_code == 0) {
          // this.$message.success(res.data.message)
          this.gData = res.data.list
          this.channal_id = 0//默認首頁
          this.channal_url = 'https://develop.interface.huangzhou.huanlong.group/h5/index.html#/pages/index/index'

          this.treeRecursion(this.gData)
        }
      }).finally(r => {
        this.clickFlag = false
      })
    },
    // 获取频道类型下拉列表
    getTypeList() {
      let query = Object.assign({}, this.query)
      GetChannelType(query).then(res => {
        if (res.error_code == 0) {
          this.typesList = res.data.list
        }
      })
    },
    // 添加频道
    addChannel() {
      if (!this.channalid) {
        let data = {
          pid: this.pid,
          name: this.valueName,
          type_id: this.typesValue,
          pic: this.query.pic
        }
        AddChannel(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.getList()
          }
        }).finally(e => {
          this.query.pic = undefined
        })
      } else {//编辑
        let data = {
          id: this.channalid,
          pid: this.pid,
          name: this.valueName,
          type_id: this.typesValue,
          pic: this.query.pic
        }
        EditChannel(data).then(res => {
          if (res.error_code == 0) {
            this.$message.success(res.data.message)
            this.getList()
          }
        }).finally(e => {
          this.query.pic = undefined
        })
      }
    },
    onExpand(expandedKeys) {
      //用户点击展开时，取消自动展开效果
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    // 选择频道
    onchangeTree(e) {
      this.channal_id = e[0].includes('-') ? undefined : e[0]
      if (e[0] == 1) {//首页
        this.channal_url = 'https://develop.interface.huangzhou.huanlong.group/h5/index.html#/pages/index/index'
      } else if (e[0] == 2) {//服务
        this.channal_url = 'https://develop.interface.huangzhou.huanlong.group/h5/index.html#/pages/serve/serve?id=2'
      } else if (e[0] == 3) {//宏图榜
        this.channal_url = 'https://develop.interface.huangzhou.huanlong.group/h5/index.html#/pages/billboard/billboard?id=3'
      } else if (e[0] == 4) {//平台
        this.channal_url = 'https://develop.interface.huangzhou.huanlong.group/h5/index.html#/pages/platform/platform?id=4'
      } else if (this.channal_id) {
        this.getquery(this.gData, e[0])
        this.channal_url = 'https://develop.interface.huangzhou.huanlong.group/h5/index.html#/pages/index/transfer?type=' + this.channal_obj.type + '&title=' + this.channal_obj.title + '&id=' + e[0]
      } else {
        // this.channal_url = undefined
      }

    },
    getquery(data, id) {
      data.forEach(el => {
        if (el.id == id) {
          this.channal_obj.type = el.type_id
          this.channal_obj.title = el.title
        }
        if (el.child && el.child.length) this.getquery(el.child, id);
      })
    },
    beforeUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 10
        if (isLt1M) {
          self.$message.error('上传文件大于10MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    async uploadFiles(info, id) {
      console.log(id, '///uploadFiles');
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };

      let uploadApiUrl = this.baseUrl + 'backend/v1/tools/upload_file_to_oos'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.query.pic = res.data.file_url
        // 递归展示id的pic
        if (id) this.changPic(this.gData, id)
        // 回显
        // this.getList()
        // 展开列表
        // this.expandedKeys.push(id)

      } else {
        this.$message.error(res.error.message)
      }
    },
    changPic(data, id) {
      data.forEach(el => {
        if (el.id == id) {
          el.pic = this.query.pic
        }
        if (el.child && el.child.length) this.changPic(el.child, id);
      })
    },
    handleChangeTy(info, i) {
      let self = this
      let obj = {}
      if (info.file.status !== 'uploading') {
        self.loading = true
      }
      if (info.file.status === 'done') {
        self.loading = false
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败.`);
      }
    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        // "Content-Type": "multipart/form-data",
        "Authorization": storage.get('token')
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    clickEvent(event, id) {
      this.statusIsshow = event.target.parentNode
      this.statusIsshow.style.display = 'inlline-block'
    },
  },
  created() {
    this.getList()
    this.getTypeList()
  },
}
</script>
<style lang="less" scoped>
.channel {
  margin: 18px 12px 18px 18px;
  background: #f0f5f4;
  height: calc(100vh - 88px);
  overflow: hidden;
  .demo {
    background: #fff;
    height: 100%;
    width: 451px;
    border-radius: 4px;
    padding: 33px 38px 0 38px;
    display: flex;
    flex-direction: column;
  }
  .codes {
    flex: 1;
    height: 100%;
    border-radius: 4px;
    background: #fff;
    padding: 33px 24px 0 33px;
    // overflow: hidden;
    // overflow-y: auto;
  }
}
.tpointer {
  display: inline-block;
  width: 3px;
  height: 10px;
  background: @primaryColor;
  border-radius: 2px;
  margin: 7px 8px 1px 0;
}
.ttxt {
  // height: 22px;
  font-size: 16px;
  font-weight: 600;
  color: @fontColor1;
  // line-height: 22px;
}
.phone_pic {
  width: 375px;
  height: 100%;
  flex: 1;

  overflow: hidden;
  overflow-y: auto;
  .phone_box {
    height: 100%;
    padding-bottom: 20px;
    background: #f3f9fe;
    pointer-events: none;
    font-size: 0;
    -webkit-overflow-scrolling: touch;
    overflow: scroll;
  }
}
.table-btn-clk {
  letter-spacing: 0.5px;
  margin-left: 0px;
}
.table_head {
  width: 100%;
  height: 40px;
  padding-left: 12px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 500;
  color: @fontColor1;
  border-top: 1px solid #a6dad7;
  background: rgba(236, 244, 243, 0.41);
  box-shadow: inset 0px -2px 0px 0px rgba(193, 212, 211, 0.31);
  .one {
    margin-left: 0;
    padding-left: 0;
  }
}
.mgb-6 {
  margin-bottom: 6px;
}
.font-12 {
  font-size: 12px;
}
.wid_298 {
  display: inline-block;
  // padding-left: 16px;
  width: 258px;
}

.wid-180 {
  width: 180px;
}
.pointer_tree {
  display: block;
  margin-top: 14px;
  width: 6px;
  height: 6px;
  background: @fontColor5;
  border-radius: 50%;
  margin-left: 2px;
  margin-top: 20px;
}

.table_body {
  position: relative;
  padding-left: 14px;
  overflow: hidden;
  overflow-y: auto;
  height: calc(100% - 160px);
  .title1 {
    display: inline-block;
    width: 946px;
    // overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .title1_sp {
      display: inline-block;
      width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .title2 {
    display: inline-block;
    position: absolute;
    left: 272px;
    min-width: 200px;
  }
  .title3 {
    // display: inline-block;
    display: none;
    position: absolute;
    // left: 500px;
    left: 580px;
    .msbtn {
      background: transparent;
    }
    .msbtn {
      &:hover {
        background: #fff;
      }
    }
  }
  .title4 {
    display: inline-block;
    position: absolute;
    left: 546px;
    min-width: 200px;
  }
  .edit_title3 {
    display: inline-block;
    padding-left: 68px;
  }
  .bgLine {
    &:hover {
      .title3 {
        display: inline-block;
      }
    }
  }
}

/deep/.ant-btn.msbtn {
  width: 24px;
  height: 24px;
  line-height: 18px;
  .meiye-icon {
    font-size: 12px;
    color: @primaryColor;
  }
}
.msbtn {
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  background: #fcffff;
  border-radius: 4px;
  color: @primaryColor;
  text-align: center;
}
/deep/.ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
  display: none;
}
.bgLine {
  // background: #fff;
  &:hover {
    .title3 {
      display: inline-block;
    }
  }
}
/deep/.ant-tree-treenode-switcher-close {
  height: 44px;
  &:hover {
    background: #f1f9f7;
    /deep/.ant-tree-title {
      background: #f1f9f7;
    }
  }
}
/deep/ .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background: transparent;
  background: #f1f9f7;
  width: 100%;
}
/deep/ .ant-tree li .ant-tree-node-content-wrapper {
  margin-bottom: 4px;
}
/deep/.ant-tree li .ant-tree-node-content-wrapper:hover {
  // background-color: transparent !important;//
  background: #f1f9f7 !important;
}
/deep/.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  // background: transparent;
}
.height-32 {
  height: 32px;
  display: inline-block;
}
/deep/.ant-tree li span.ant-tree-switcher,
.ant-tree li span.ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 36px;
  margin: 0;
  line-height: 36px;
  text-align: center;
  vertical-align: top;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
/deep/.ant-tree li span[draggable],
.ant-tree li span[draggable="true"] {
  line-height: 44px;
  height: 44px;
  margin-top: -4px;
  border: none;
  width: 100%;
}
/deep/ .ant-tree > li:first-child {
  padding-top: 4px;
}
/deep/.ant-tree-child-tree > li:first-child {
  padding-top: 4px;
}
/deep/.ant-tree li span.ant-tree-switcher,
.ant-tree li span.ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 36px;
  margin: 0;
  line-height: 36px;
  text-align: center;
  vertical-align: top;
  border: 0 none;
  outline: none;
  cursor: pointer;
}
/deep/.ant-tree li.drag-over > span[draggable] {
  background-color: @primaryColor !important;
}
/deep/.ant-tree li.drag-over-gap-top > span[draggable] {
  border-top-color: @primaryColor !important;
}
/deep/.ant-tree li.drag-over-gap-bottom > span[draggable] {
  border-bottom-color: @primaryColor !important;
}

#iframe {
  display: block;
  height: 100%;
  vertical-align: top;
  // min-height: 667px;
  // min-height: 765px;
  // min-height: 667px;
}
.uploadpopBox {
  width: 32px;
  height: 32px;
  position: relative;
  cursor: pointer;
  border-radius: 2px;

  .uploadpop {
    display: none;
    position: absolute;
    top: 7px;
    left: 7px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    // background: rgba(0, 0, 0, 0.25);
    text-align: center;
    line-height: 18px;
    span {
      display: inline-block;
      color: #fff;
      font-size: 12px;
      transform: scale(0.8);
    }
  }
  &:hover {
    background: #fff;

    img {
      // background: rgba(0, 0, 0, 0.25) !important;
      // border-radius: 2px !important;
    }
    .uploadpop {
      background: rgba(0, 0, 0, 0.65) !important;
      display: inline-block;
    }
  }
}
.typeicon {
  width: 32px;
  height: 32px;
  // background: @bgColor6;
  border-radius: 2px;
  border: 1px dashed @baseColor40;
  // border: 1px dashed @baseColor98;
  text-align: center;
  line-height: 30px;
  box-sizing: border-box;
  &:hover {
    border: 1px dashed @primaryColor;
  }
}
@media screen and (min-width: 1538px) {
  .wid_298 {
    display: inline-block;
    // padding-left: 16px;
    width: 298px;
    font-weight: 600;
  }
  .table_body .title2 {
    left: 312px;
  }
  .table_body .title3 {
    left: 780px;
  }
  .table_body .title4 {
    left: 612px;
  }
}
@media screen and (min-width: 1208px) and (max-width: 1538px) {
  .wid_298 {
    display: inline-block;
    // padding-left: 16px;
    width: 230px;
    font-weight: 600;
  }
  .table_body .title2 {
    left: 246px;
  }
  .table_body .title3 {
    left: 560px;
  }
  .table_body .title4 {
    left: 475px;
  }
  .table_body .title1 {
    display: inline-block;
    width: 631px;
    // overflow: hidden;
  }
  .table_body .edit_title3 {
    padding-left: 0;
  }
}

/deep/.ant-tree.ant-tree-directory
  > li.ant-tree-treenode-selected
  > span.ant-tree-node-content-wrapper::before,
/deep/.ant-tree.ant-tree-directory
  .ant-tree-child-tree
  > li.ant-tree-treenode-selected
  > span.ant-tree-node-content-wrapper::before {
  // background: #e6f7ff;
  height: 48px;
}
/deep/.ant-tree.ant-tree-directory
  > li
  span.ant-tree-node-content-wrapper.ant-tree-node-selected,
/deep/
  .ant-tree.ant-tree-directory
  .ant-tree-child-tree
  > li
  span.ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: rgba(0, 0, 0, 0.65);
}
/deep/
  .ant-tree.ant-tree-directory
  > li.ant-tree-treenode-selected
  > span.ant-tree-switcher,
/deep/.ant-tree.ant-tree-directory
  .ant-tree-child-tree
  > li.ant-tree-treenode-selected
  > span.ant-tree-switcher {
  color: rgba(0, 0, 0, 0.65);
  border: none;
}
/deep/.ant-tree.ant-tree-directory
  > li
  span.ant-tree-node-content-wrapper::before,
/deep/.ant-tree.ant-tree-directory
  .ant-tree-child-tree
  > li
  span.ant-tree-node-content-wrapper::before {
  position: absolute;
  right: 0;
  left: 0;
  height: 48px;
  transition: all 0.3s;
  content: "";
  margin-top: -4px;
}
/deep/ .ant-tree-treenode-switcher-close:hover {
  // background: #e6f7ff;
}
/deep/ .ant-tree-treenode-switcher-close {
  // padding-top: 2px;
}
/deep/ .ant-tree-treenode-switcher-close:hover {
  // padding-top: 2px;
}
/deep/.ant-tree li {
  margin-bottom: 4px;
}
/deep/ .ant-tree-treenode-switcher-open.ant-tree-treenode-selected {
  // height: 44px;
  // border-bottom: none;
}
/deep/ .ant-btn.small_primary {
  padding: 1px 7px 2px 5px;
}
/deep/ .ant-btn.smallbtn {
  padding: 1px 7px 2px 5px;
}
.pdb-24 {
  padding-bottom: 24px;
}
/deep/.ant-tree-treenode-selected .meiye-jiantou_tree1 {
  color: @fontColor4 !important;
}
/deep/.ant-tree-treenode-switcher-close .meiye-jiantou_tree1 {
  color: @fontColor5 !important;
}
/deep/.ant-tree-treenode-switcher-close.ant-tree-treenode-selected {
  background: #f1f9f7;
}
/deep/.ant-tree-node-selected .title3 {
  display: inline-block;
}
/deep/.ant-tree li ul {
  margin: 0;
  padding: 0 0 0 24px;
}
</style>